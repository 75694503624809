
import { defineComponent, computed, ref, watch } from 'vue'

type TimerState = 'idle' | 'running' | 'done'

const TIMER_INTERVAL = 1000

export default defineComponent({
  name: 'App',
  props: {},
  setup () {
    const initialCount = ref(3)
    const state = ref<TimerState>('idle')
    const count = ref(3)
    const timer = ref(0)

    const stop = (s: TimerState = 'idle') => {
      state.value = s
      window.clearInterval(timer.value)
      timer.value = 0
    }

    const reset = () => {
      stop()
      count.value = initialCount.value
    }

    const start = () => {
      if (state.value === 'running') return
      if (state.value === 'done') reset()

      state.value = 'running'
      timer.value = window.setInterval(() => {
        count.value -= 1
        if (count.value === 0) {
          stop('done')
        }
      }, TIMER_INTERVAL)
    }

    const toggle = () => {
      if (state.value === 'running') return reset()
      if (state.value === 'done') return reset()
      start()
    }

    const blur = () => {
      if (!initialCount.value) {
        initialCount.value = 3
      }
    }

    watch(initialCount, (val) => { count.value = val })

    return {
      state,
      toggle,
      blur,
      initialCount,
      count: computed(() => count.value)
    }
  }
})
