<template>
  <div class="wrapper">
    <img src="/images/count-down-logo.svg" class="logo" />
    <div class="timer" :class="`timer--${state}`">
      <transition-group name="count">
        <template v-if="state === 'idle'">
          <span class="count count--input">
            <input
              type="number"
              min="1"
              v-model="initialCount"
              @blur="blur"
            />
          </span>
        </template>
        <template v-if="state === 'done'">
          <span class="count" :key="'done'">GO</span>
        </template>
        <template v-if="state === 'running'">
          <span class="count" :class="{ 'count--first': initialCount === count }" :key="`running-${count}`">{{ count }}</span>
        </template>
      </transition-group>
    </div>
    <div class="controls">
      <button @click="toggle" class="btn">
        {{
          state === 'running' ? 'reset'
          : state === 'done' ? 'reset'
          : 'start'
        }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue'

type TimerState = 'idle' | 'running' | 'done'

const TIMER_INTERVAL = 1000

export default defineComponent({
  name: 'App',
  props: {},
  setup () {
    const initialCount = ref(3)
    const state = ref<TimerState>('idle')
    const count = ref(3)
    const timer = ref(0)

    const stop = (s: TimerState = 'idle') => {
      state.value = s
      window.clearInterval(timer.value)
      timer.value = 0
    }

    const reset = () => {
      stop()
      count.value = initialCount.value
    }

    const start = () => {
      if (state.value === 'running') return
      if (state.value === 'done') reset()

      state.value = 'running'
      timer.value = window.setInterval(() => {
        count.value -= 1
        if (count.value === 0) {
          stop('done')
        }
      }, TIMER_INTERVAL)
    }

    const toggle = () => {
      if (state.value === 'running') return reset()
      if (state.value === 'done') return reset()
      start()
    }

    const blur = () => {
      if (!initialCount.value) {
        initialCount.value = 3
      }
    }

    watch(initialCount, (val) => { count.value = val })

    return {
      state,
      toggle,
      blur,
      initialCount,
      count: computed(() => count.value)
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

:root {
  --text-color: 222;
  --background-color: #fafafa;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto Mono', Monaco, Menlo, monospace;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  user-select: none;
}

#app {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.wrapper {
  width: 50vw;
  min-width: 320px;
  text-align: center;
}
.timer {
  line-height: 1.25em;
  height: 1.25em;
  display: block;
  font-size: unquote("max(20vw, #{10em})");
  font-weight: bold;
  margin-bottom: 1px;
  text-align: center;
  position: relative;
  margin-bottom: 5vh;
  color: #919191;

  &--running,
  &--done {
    color: #191919;
  }
}

.count {
  position: absolute;
  left: 0;
  width: 100%;
}

input[type="number"] {
  border: 0;
  background: 0;
  color: inherit;
  font: inherit;
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: inherit;
  appearance: text;
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus,
  &:active {
    outline: 0;
    box-shadow: 0;
  }
}

.btn {
  font-family: inherit;
  padding: 1.5em 3em;
  text-align: center;
  background: none;
  border: 1px solid rgba(black, 0.125);
  border-radius: 3px;
  font-weight: bold;
  font-size: unquote("max(1vw, #{1.125em})");
  letter-spacing: 0.125em;
  text-transform: uppercase;
  width: 10em;
  cursor: pointer;
  color: inherit;
  opacity: 0.35;
  transition: all ease-in-out 100ms;
  transition-property: color, background-color, border-color, box-shadow, transform;
  outline: none;

  &:hover {
    background-color: rgba(white, 0.075);
    opacity: 1;
  }

  &:active {
    box-shadow: 0;
    transform: translateY(2px);
    box-shadow: inset 0 2px 4px rgba(black, 0.125);
    background-color: rgba(black, 0.0075);
  }
}

.count {
  transition: all 350ms ease-out;

  &--first.count-enter-active,
  &--input.count-leave-active {
    transition: none;
  }
  &-leave-active {
    transition-timing-function: ease-in;
  }
  &-leave-to {
    transform: translateY(-30px);
  }
  &-enter-active {
    transition-duration: 700ms;
    transition-timing-function: ease-out;
  }
  &-enter-from {
    transform: translateY(30px);
  }

  &-leave-to,
  &-enter-from {
    opacity: 0;
  }
}

.logo {
  position: absolute;
  bottom: 30px;
  left: calc(50% - 15px);
  width: 30px;
  pointer-events: none;
}

</style>
